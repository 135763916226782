var React = require('react');

function Badge19661980 (props) {
    return React.createElement("svg",props,React.createElement("polygon",{"className":"badge-body","points":"66,1 1,1 14,31 79,31","strokeWidth":"1"}));
}

Badge19661980.defaultProps = {"version":"1.1","viewBox":"0 0 80 32","width":"80","height":"32","preserveAspectRatio":"none"};

module.exports = Badge19661980;

Badge19661980.default = Badge19661980;
