var React = require('react');

function Badge20112019 (props) {
    return React.createElement("svg",props,React.createElement("polygon",{"className":"badge-body","points":"14,1 1,31 66,31 79,1","strokeWidth":"1"}));
}

Badge20112019.defaultProps = {"version":"1.1","viewBox":"0 0 80 32","width":"70","height":"32","preserveAspectRatio":"none"};

module.exports = Badge20112019;

Badge20112019.default = Badge20112019;
