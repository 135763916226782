// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-year-range-js": () => import("./../src/templates/yearRange.js" /* webpackChunkName: "component---src-templates-year-range-js" */),
  "component---src-templates-gender-js": () => import("./../src/templates/gender.js" /* webpackChunkName: "component---src-templates-gender-js" */),
  "component---src-templates-story-js": () => import("./../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

