var React = require('react');

function Badge19501965 (props) {
    return React.createElement("svg",props,React.createElement("polygon",{"className":"badge-body","points":"53,1 11,1 1,15 11,31 53,31 63,15","strokeWidth":"1"}));
}

Badge19501965.defaultProps = {"version":"1.1","viewBox":"0 0 64 32","width":"64","height":"32","preserveAspectRatio":"none"};

module.exports = Badge19501965;

Badge19501965.default = Badge19501965;
