var React = require('react');

function Badge19811995 (props) {
    return React.createElement("svg",props,React.createElement("path",{"className":"badge-body","d":"M62,1l-44,0c-7.236,0 -13,6.667 -13,15c0,8.333 5.764,15 13,15l44,0c7.236,0 13,-6.667 13,-15c0,-8.333 -5.764,-15 -13,-15Z"}));
}

Badge19811995.defaultProps = {"viewBox":"0 0 80 32","version":"1.1","width":"80","height":"32","preserveAspectRatio":"none"};

module.exports = Badge19811995;

Badge19811995.default = Badge19811995;
