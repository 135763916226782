var React = require('react');

function Badge19962010 (props) {
    return React.createElement("svg",props,React.createElement("rect",{"x":"8","y":"1","className":"badge-body","width":"48","height":"30","strokeWidth":"1"}));
}

Badge19962010.defaultProps = {"version":"1.1","viewBox":"0 0 64 32","width":"64","height":"32","preserveAspectRatio":"none"};

module.exports = Badge19962010;

Badge19962010.default = Badge19962010;
